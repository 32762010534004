import React from 'react';

interface Image {
  imagePath: string;
  height?: string;
  width?: string;
  loading?: 'eager' | 'lazy';
}

export const serverUrl = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:1337';

export function getPathToServerImage(imagePath: string): string {
  return serverUrl + imagePath;
}

function Image({imagePath, height, width, loading}: Image): any {
  return (
    <img loading={loading || 'lazy'} src={getPathToServerImage(imagePath)} alt="" height={height} width={width}/>
  );
}

export default Image;
