import React from 'react';

function Loading(): any {
  return (
    <div className="page-block-padding-x page-block-padding-y d-flex justify-content-center"
         style={{backgroundColor: 'var(--cream)'}}>
      <svg x="0px"
           y="0px"
           width="160px"
           height="160px"
           viewBox="0 0 60 60">
        <path fill="#00B6BC"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
          <animateTransform attributeType="xml"
                            attributeName="transform"
                            type="rotate"
                            from="0 25 25"
                            to="360 25 25"
                            dur="0.6s"
                            repeatCount="indefinite"/>
        </path>
      </svg>
    </div>
  );
}

export default Loading;
