import React, {Suspense} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";

import {LanguageProvider} from "./containers/lang/Language";
import Header from "./containers/header/Header";
import Footer from "./containers/footer/Footer";
import Announcement from "./containers/announcement/Announcement";

import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import AppRoutes from "./containers/router/AppRoutes";
import Loading from "./components/loading/Loading";

import "./App.css";

const renderLoader = () => <Loading/>;

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00B6BC',
      main: '#00B6BC',
      dark: '#00B6BC',
      contrastText: '#fff',
    },
    secondary: {
      light: '#050A39',
      main: '#050A39',
      dark: '#050A39',
      contrastText: '#fff',
    },
    error: {
      main: '#C80D0D'
    }
  },

  typography: {
    fontFamily: `'Montserrat', 'sans-serif'`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  }
});

const cache = new InMemoryCache();
const client = new ApolloClient({
  cache: cache,
  uri: process.env.NODE_ENV === "production" ? "/graphql" : "http://localhost:1337/graphql",
});

function App() {
  return (
    <ApolloProvider client={client}>
      <MuiThemeProvider theme={theme}>
        <LanguageProvider>
          <Router>
            <Announcement/>
            <Header/>
            <Suspense fallback={renderLoader()}>
              <AppRoutes/>
            </Suspense>
            <Footer/>
          </Router>
        </LanguageProvider>
      </MuiThemeProvider>
    </ApolloProvider>
  );
}

export default App;
