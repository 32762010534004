import {ComponentType, PageButton} from "../../pages/common/Common.model";

export interface HeaderMenuData {
  button: PageButton;
  body: MenuItem[];
}

export interface MenuItem {
  __typename: ComponentType.ComponentMenuLinks | ComponentType.ComponentPageButton
  id: string;
  order: number;
  visible: boolean;
  label_ua: string;
  label_ru: string;
  label_en: string;
  slug: string;
}

export enum HeaderContactComponentType {
  ComponentCommonContactsList = 'ComponentCommonContactsList',
  ComponentCommonSchedule = 'ComponentCommonSchedule'
}

export interface HeaderContactSchedule {
  __typename: HeaderContactComponentType.ComponentCommonSchedule
  intervals: ScheduleInterval[]
}

export interface HeaderContactContactsList {
  __typename: HeaderContactComponentType.ComponentCommonContactsList;
  contacts: ContactItem[]
}

export interface HeaderContact {
  contactsList: HeaderContactContactsList;
  schedule: HeaderContactSchedule;
}

export interface ContactItem {
  id: string;
  order: number;
  type: string;
  value: string;
  enabled: boolean;
}

export interface ScheduleInterval {
  id: string;
  weekDays_ua: string;
  weekDays_ru: string;
  weekDays_en: string;
  title_ua: string;
  title_ru: string;
  title_en: string;
  time: string;
}

export enum ContactType {
  Email = 'email',
  Phone = 'phone',
  Viber = 'viber',
  Telegram = 'telegram',
  Whatsapp = 'whatsapp',
  Instagram = 'instagram'
}
