import React, {useContext, useEffect} from 'react';
import {LanguageContext, languageOptions} from './Language';
import {useLocation, useHistory} from "react-router-dom";
import './LanguageSelector.css';

export default function LanguageSelector() {
  // @ts-ignore
  const {userLanguage, userLanguageChange} = useContext(LanguageContext);
  const location: any = useLocation();
  const history: any = useHistory();

  // set selected language by calling context method
  const handleLanguageChange = (event: React.MouseEvent<HTMLButtonElement>, lang: string) => {
    event?.currentTarget.blur();

    // Change lang part of url after site lang changed
    const path = location.pathname.trim().split('/').slice(2).join('/');
    history.replace('/' + lang + '/' + path)

    return userLanguageChange(lang);
  }

  useEffect(() => {
    let defaultLanguage = window.localStorage.getItem('pro-orto-lang');
    // Because 'ua' was changed to 'uk' leave this check to avoid issues with incorrect saved locale
    if (!defaultLanguage || defaultLanguage === 'ua') {
      defaultLanguage = 'uk';
    }
    userLanguageChange(defaultLanguage);
  }, [userLanguageChange]);

  return (
    <ul className="lang-selector">
      {Object.entries(languageOptions).map(([id, name]) => (
        <li key={id}>
          <button className={'lang-selector__button ' + (userLanguage === id ? 'lang-selector__button_active' : '')}
                  type="button"
                  onClick={(event) => handleLanguageChange(event, id)}>
            {name}
          </button>
        </li>
      ))}
    </ul>
  );
};
