import React from 'react';
import LanguageSelector from "../../containers/lang/LanguageSelector";
import './HeaderContact.css';
import Schedule from "../schedule/Schedule";
import {HeaderContact} from '../../containers/header/Header.model'
import Contacts from "../contacts/Contacts";

interface HeaderContactProps {
  headerContact: HeaderContact;
}

function HeaderContacts({headerContact}: HeaderContactProps): any {
  return (
    <div
      className="header-contacts d-flex flex-md-row align-items-center p-3 page-block-padding-x justify-content-between">
      <LanguageSelector/>
      <div className="header-contacts__block d-flex align-items-center">
        {headerContact.contactsList && headerContact.contactsList.contacts
        && <Contacts contactsList={headerContact.contactsList}/>}
        {headerContact.schedule && headerContact.schedule.intervals
        && <Schedule schedule={headerContact.schedule}/>}
      </div>
    </div>
  );
}

export default HeaderContacts;
