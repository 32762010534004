import React, {useContext} from 'react';
import {Link, NavLink, useLocation} from "react-router-dom";
import {Text} from "../../containers/lang/Text";
import {HeaderMenuData} from "../../containers/header/Header.model"
import {ComponentType} from "../../pages/common/Common.model";
import {getLocalizedUrl, LanguageContext} from "../../containers/lang/Language";
import "./HeaderMenu.css";

interface HeaderMenuProps {
  menu: HeaderMenuData;
  toggleHamburgerMenu?: () => void;
}

function HeaderMenu({menu, toggleHamburgerMenu}: HeaderMenuProps): any {
  const languageContext = useContext(LanguageContext);
  const location = useLocation();
  const visibleMenus = menu.body
    .filter(menu => menu.visible)
    .sort((a, b) => a.order - b.order);

  const headerMenuButton = menu.body.find(item => item.__typename === ComponentType.ComponentPageButton)

  return (
    <header className="header-menu d-flex flex-md-row align-items-center p-4 page-block-padding-x">
      <a href={'/' + languageContext.userLanguage} className="header-menu__logo my-0 font-weight-normal mr-md-5">
        <img src="/images/logo.svg" alt="PRO-ORTO" width="163px" height="20px"/>
      </a>
      <nav className="header-menu__nav my-2 my-md-0 mr-md-auto">
        {visibleMenus.map((menu) => (
          <NavLink key={menu.id}
                   activeClassName="active"
                   exact
                   isActive={(match) => !!match}
                   className="p-3 header-menu__nav-item"
                   onClick={toggleHamburgerMenu}
                   to={getLocalizedUrl(menu.slug)}><Text item={menu} prop="label"/></NavLink>
        ))}
      </nav>
      {headerMenuButton &&
      <Link className="client-request__btn btn-orto-transparent"
            to={{
              pathname: getLocalizedUrl(headerMenuButton.slug),
              // This is the trick! This link sets
              // the `background` in location state.
              state: {background: location}
            }}>
          <Text item={headerMenuButton} prop="title"/>
      </Link>}
    </header>

  );
}

export default HeaderMenu;
