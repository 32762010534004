import React, {createContext, useState} from 'react';

export const languageOptions = {
  uk: 'UA',
  en: 'EN',
};

// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: 'uk'
});

export function getLocalizedUrl(url: string): string {
  const isOutsideLink = url.split('http')?.length > 1;
  const lang = window.localStorage.getItem('pro-orto-lang')

  return isOutsideLink ? url : `/${lang}${url}`;
}

// it provides the language context to app
// @ts-ignore
export function LanguageProvider({children}) {
  const [userLanguage, setUserLanguage] = useState('uk');

  const provider = {
    userLanguage,
    userLanguageChange: (selected: string) => {
      const allowedLanguages = Object.keys(languageOptions);
      const isLangCorrect = allowedLanguages.some(lang => lang === selected);

      if (!isLangCorrect) {
        return;
      }

      setUserLanguage(selected);
      window.localStorage.setItem('pro-orto-lang', selected);
    }
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
}
