import React from 'react';
import {gql, useQuery} from '@apollo/client';
import {Text} from "../lang/Text";
import './Announcement.css';
import useLocalStorage from "../../hooks/useLocalStorage";

const GET_ANNOUNCEMENT = gql`
    {
        announcement {
            enabled,
            showFrom,
            showTo,
            status,
            updatedAt,
            text_ua,
            text_ru,
            text_en
        }
    }
`;

enum AnnouncementStatus {
  info = 'info',
  danger = 'danger'
}

interface Announcement {
  enabled?: boolean;
  showFrom?: string;
  showTo?: string;
  status: AnnouncementStatus;
  updatedAt: string;
  text_ua: string;
  text_ru: string;
  text_en: string;
}

function Announcement(): any {
  const {loading, error, data} = useQuery<{ announcement: Announcement }>(GET_ANNOUNCEMENT);
  const [announcementVisibility, setAnnouncementVisibility] = useLocalStorage('proOrto_announcementVisible', '');
  if (loading || !data) return '';
  if (error) return '';
  if (!data.announcement.enabled) return '';

  function isAnnouncementVisible(showFrom: string, showTo: string): boolean {
    const currentDate = Date.now();
    const dateFrom = Date.parse(showFrom);
    const dateTo = Date.parse(showTo);

    return dateFrom < currentDate && currentDate < dateTo && (!!data && announcementVisibility !== data.announcement.updatedAt);
  }

  if (!data.announcement.showFrom || !data.announcement.showTo || !isAnnouncementVisible(data.announcement.showFrom, data.announcement.showTo)) {
    return '';
  }

  function getStatusColor(status: AnnouncementStatus): string {
    switch (status) {
      case AnnouncementStatus.info:
        return 'green';

      case AnnouncementStatus.danger:
        return 'red'

      default:
        return 'green';
    }
  }

  function closeAnnouncement(updatedAt: string): void {
    setAnnouncementVisibility(updatedAt);
  }

  return (
    <div className="announcement-block d-flex page-block-padding-x"
         style={{backgroundColor: 'var(--' + getStatusColor(data.announcement.status) + ')'}}>
      <div className="announcement-text"><Text item={data.announcement} prop="text"/></div>
      <button className="btn-orto-close announcement-close-btn"
              onClick={() => closeAnnouncement(data.announcement.updatedAt)}>x
      </button>
    </div>
  );
}

export default Announcement;
