import React, {useState} from 'react';
import {gql, useMutation} from '@apollo/client';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import InputMask from 'react-input-mask';
import {useForm, Controller} from 'react-hook-form';
import {ComponentPageRequestForm, FormInput} from "../../pages/common/Common.model";
import {Text} from "../../containers/lang/Text";
import Image from "../../components/image/Image";
import Button from "../button/Button";

import './RequestForm.css';
import {getLocalizedUrl} from "../../containers/lang/Language";

const CREATE_DOCTOR_REQUEST = gql`
    mutation CreateDoctorRequest(
        $name: String!,
        $phone: String!,
        $day: String!,
        $time: String!,
        $comment: String
    ) {
        createDoctorRequest(input: {
            data: {
                name: $name,
                phone: $phone,
                day: $day,
                time: $time,
                comment: $comment
            }
        }
        ) {
            doctorRequest {
                name
            }
        }
    }
`

interface RequestForm {
  formData: ComponentPageRequestForm,
  isOpenedInModal?: boolean;
  closeModal?: () => void;
  setFormStateClass?: (cl: string) => void;
}

interface RequestFormState {
  name: string;
  phone: string;
  day: string;
  time: string;
  comment: string;
  isPersonalDataProcessingAllowed: boolean;
}

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  mask: string;
  formDataInput: FormInput;
  register: any;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const {inputRef, mask, register, ...other} = props;

  return (
    <InputMask
      {...other}
      inputRef={register({required: true})}
      mask={mask}
      alwaysShowMask={true}
    />
  );
}

function RequestForm({formData, isOpenedInModal, closeModal, setFormStateClass}: RequestForm): JSX.Element {
  const getFormElementLabel = (item: any, prop: string, isRequired: boolean, nonRequiredItem: any, nonRequiredProp: string): React.ReactNode => {
    const labelText = Text({item, prop});
    const optionalText = (!isRequired ? ' ' + Text({item: nonRequiredItem, prop: nonRequiredProp}) : '');
    return (<span>{labelText}<span className="request-form-label_optional">{optionalText}</span></span>);
  }

  const getFormElementLabelWithRequired = (item: any, prop: string, isRequired: boolean): React.ReactNode => {
    return getFormElementLabel(item, prop, isRequired, formData, 'nonRequiredLabel');
  }

  const getFormattedTime = (from: string, to: string): string => {
    const splitFrom = from.split(':');
    const splitTo = to.split(':');

    return `${splitFrom[0]}:${splitFrom[1]} - ${splitTo[0]}:${splitTo[1]}`;
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        width: '100%',
      },
    },
    select: {
      borderRadius: 0
    },
  }));

  const {
    register,
    handleSubmit,
    errors,
    formState,
    control
  } = useForm(/*{mode: 'onBlur', reValidateMode: 'onChange'}*/);
  const [createRequest] = useMutation(CREATE_DOCTOR_REQUEST, {errorPolicy: 'none'});
  const [afterSubmitState, afterFormSubmit] = useState<{ success: boolean, title: string; message: string }>({
    success: false,
    title: '',
    message: ''
  });
  const [requestFormState, setFormState] = useState<RequestFormState>({
    name: '',
    phone: '',
    day: formData.daySelect.day_slots[0].label_ua,
    time: getFormattedTime(formData.timeSelect.time_slots[0].from, formData.timeSelect.time_slots[0].to),
    comment: '',
    isPersonalDataProcessingAllowed: false
  });

  const handleInputChange = (event: any) => {
    setFormState({...requestFormState, [event.target.name]: event.target.value});
  };

  const handleCheckboxChange = (event: any) => {
    setFormState({...requestFormState, [event.target.name]: event.target.checked});
  };

  const classes = useStyles();
  const formSuccessMessageTitle = Text({item: formData, prop: 'successMessageTitle'});
  const formSuccessMessage = Text({item: formData, prop: 'successMessage'});
  const formErrorMessageTitle = Text({item: formData, prop: 'errorMessageTitle'});
  const formErrorMessage = Text({item: formData, prop: 'errorMessage'});

  async function handleCreateRequest() {
    try {
      await createRequest({
        variables: {
          name: requestFormState.name,
          phone: requestFormState.phone,
          day: requestFormState.day,
          time: requestFormState.time,
          comment: requestFormState.comment,
          isPersonalDataProcessingAllowed: requestFormState.isPersonalDataProcessingAllowed
        }
      })
      afterFormSubmit({
        success: true,
        title: formSuccessMessageTitle,
        message: formSuccessMessage || 'Your request was successfully send!'
      });
      if (setFormStateClass) {
        setFormStateClass('success');
      }
    } catch (e) {
      afterFormSubmit({
        success: false,
        title: formErrorMessageTitle,
        message: formErrorMessage || 'Something went wrong! Please try again later.'
      });
      if (setFormStateClass) {
        setFormStateClass('error');
      }
    }
  }

  return (
    <div className="request-form-block d-flex page-block-padding-x page-block-padding-y"
         style={{backgroundColor: 'var(--' + formData.blockBgColor + ')'}}>
      <h2 className="request-form__title request-form__title_small-screen">
        {afterSubmitState.title}
        {!afterSubmitState.title && <Text item={formData} prop="title"/>}
      </h2>
      {!isOpenedInModal && <div className="request-form-image">
          <div className="request-form-dots request-form-dots_top">&nbsp;</div>
          <Image imagePath={formData.image.url}/>
          <div className="request-form-dots request-form-dots_bottom">&nbsp;</div>
      </div>}
      <div
        className="request-form-data">
        <h2 className="request-form__title">
          {afterSubmitState.title}
          {!afterSubmitState.title && <Text item={formData} prop="title"/>}
        </h2>

        {afterSubmitState.message && <div>{afterSubmitState.message}</div>}

        {!afterSubmitState.title && !afterSubmitState.message &&
        <form className={classes.root}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(handleCreateRequest)}>
            <Grid container>

              {formData.name &&
              <Grid item xs={12} className="request-form__item">
                  <TextField required={formData.name.required}
                             inputRef={register({
                               required: Text({item: formData.name.errorMessages?.required, prop: 'message'})
                             })}
                             error={!!errors.name}
                             helperText={errors.name && errors.name.message}
                             onChange={handleInputChange}
                             name="name"
                             inputProps={{maxLength: 100, 'aria-label': 'Name'}}
                             label={getFormElementLabelWithRequired(formData.name, "label", formData.name.required)}
                             fullWidth/>
              </Grid>}

              {formData.phone &&
              <Grid item xs={12} className="request-form__item-phone">
                  <Controller name="phone"
                              rules={{
                                pattern: {
                                  value: /^[+][0-9]{2}[(]{1}[0-9]{3}[)]{1}[0-9]{3}-[0-9]{2}-[0-9]{2}$/g,
                                  message: Text({item: formData.phone.errorMessages?.pattern, prop: 'message'})
                                },
                              }}
                              render={(props) => (
                                <TextField
                                  name="phone"
                                  error={!!errors.phone && !!formState.touched}
                                  helperText={errors.phone && errors.phone.message}
                                  onChange={handleInputChange}
                                  inputProps={{register, mask: formData.phone.mask, 'aria-label': 'Phone'}}
                                  InputProps={{inputComponent: TextMaskCustom as any}}
                                  InputLabelProps={{shrink: true}}
                                  label={getFormElementLabelWithRequired(formData.phone, "label", formData.phone.required)}
                                  fullWidth/>
                              )}
                              control={control}
                              defaultValue=""/>
              </Grid>}

                <Grid item xs={12} className="request-form__item">
                  {formData.daySelect &&
                  <label><Text item={formData.daySelect} prop="label"/></label>}
                    <div className="d-flex justify-content-between mr-3 request-form-day-select">
                      {formData.daySelect &&
                      <Grid item xs={12} sm={5}>
                          <FormControl variant="outlined" fullWidth>
                              <Select
                                  id="day-select"
                                  className={classes.select}
                                  fullWidth
                                  value={requestFormState.day}
                                  name="day"
                                  onChange={handleInputChange}>
                                {formData.daySelect.day_slots.map(slot => (
                                  <MenuItem key={slot.id} value={slot.label_ua}>
                                    <Text item={slot} prop="label"/>
                                  </MenuItem>
                                ))}
                              </Select>
                          </FormControl>
                      </Grid>}
                        <span>&nbsp;</span>
                      {formData.timeSelect &&
                      <Grid item xs={12} sm={5}>
                          <FormControl variant="outlined" fullWidth>
                              <Select
                                  id="time-select"
                                  className={classes.select}
                                  fullWidth
                                  value={requestFormState.time}
                                  name="time"
                                  onChange={handleInputChange}>
                                {formData.timeSelect.time_slots.map(slot => (
                                  <MenuItem key={slot.id}
                                            value={getFormattedTime(slot.from, slot.to)}>
                                    {getFormattedTime(slot.from, slot.to)}
                                  </MenuItem>
                                ))}
                              </Select>
                          </FormControl>
                      </Grid>}
                    </div>
                </Grid>

              {formData.comment &&
              <Grid item xs={12} className="request-form__item">
                  <TextField multiline={true}
                             required={formData.comment.required}
                             onChange={handleInputChange}
                             name="comment"
                             inputProps={{maxLength: 1000, 'aria-label': 'Comment (optional)'}}
                             InputLabelProps={{children: '<span>123</span>'}}
                             label={getFormElementLabelWithRequired(formData.comment, "label", formData.comment.required)}
                             fullWidth/>
              </Grid>}

              {formData.personalDataProcessing &&
              <Grid item xs={12} className="request-form__item">
                  <label
                      className={'request-form-label-block d-flex MuiFormLabel-root '
                      + (!!errors.isPersonalDataProcessingAllowed ? 'Mui-error' : '')}>
                      <Checkbox
                          required={formData.personalDataProcessing.required}
                          checked={requestFormState.isPersonalDataProcessingAllowed}
                          inputRef={register({
                            required: 'Required'
                          })}
                          onChange={handleCheckboxChange}
                          name="isPersonalDataProcessingAllowed"
                          color="primary"/>
                      <span className="request-form-label">
                      <Text item={formData.personalDataProcessing} prop="label"/><span>&nbsp;</span>
                        {formData.personalDataProcessing.labelLink_ua &&
                        <a href={getLocalizedUrl(formData.personalDataProcessing.slug || '')}><Text
                            item={formData.personalDataProcessing} prop="labelLink"/></a>
                        }
                    </span>
                  </label>
              </Grid>}

                <div className="request-form__action-btns">
                  {formData.submitBtn &&
                  <Button type="submit"
                          customClass="request-form-btn"
                          textItem={formData.submitBtn}
                          textProp="label"
                          classType={formData.submitBtn.type}/>}

                  {formData.cancelBtn && isOpenedInModal &&
                  <Button type="button"
                          customClass="request-form-btn_close"
                          textItem={formData.cancelBtn}
                          textProp="label"
                          onClick={closeModal}
                          classType={formData.cancelBtn.type}/>}
                </div>

            </Grid>
        </form>}
      </div>
    </div>
  );
}

export default RequestForm;
