import React, {useContext, useEffect} from "react";
import {Route, useHistory, useLocation} from "react-router-dom";
import {LanguageContext, languageOptions} from "../lang/Language";
import {RouteConfig} from "./RoutesConfig";

export default function RouteWithSubRoutes(route: RouteConfig) {
  // @ts-ignore
  const {userLanguage, userLanguageChange} = useContext(LanguageContext);
  const location = useLocation()
  const history = useHistory();

  useEffect(() => {
    const path: string[] = location.pathname.trim().split('/');

    // In case route to / need to redirect to /uk /ru /en
    if (location.pathname.trim() === '/') {
      let defaultLanguage = window.localStorage.getItem('pro-orto-lang');
      // Because 'ua' was changed to 'uk' leave this check to avoid issues with incorrect saved locale
      if (!defaultLanguage || defaultLanguage === 'ua') {
        defaultLanguage = 'uk';
      }
      history.replace('/' + defaultLanguage);
    }

    // In case of open direct url need to set correct site language from url
    const allowedLanguages: string[] = Object.keys(languageOptions);
    const langInPath = path.find(p => allowedLanguages.find(lang => p === lang));

    if (langInPath) {
      userLanguageChange(langInPath);
    }
  }, [location, history, userLanguageChange])

  return (
    <Route
      path={'/' + userLanguage + route.path}
      exact={route.exact}
      children={props => {
        return (
          <div>
            <route.children {...props} routes={route.routes}/>
          </div>
        );
      }}
    />
  );
}
