import React from 'react';
import {gql, useQuery} from '@apollo/client';
import {Text} from "../../containers/lang/Text";
import {getLocalizedUrl} from "../lang/Language";
import './Footer.css';

const GET_CONTACTS = gql`
    {
        footerMenu {
            sections {
                __typename
                ... on ComponentMenuSection {
                    id,
                    label_ua,
                    label_ru,
                    label_en,
                    slug,
                    visible,
                    order,
                    links {
                        id,
                        label_ua,
                        label_ru,
                        label_en,
                        slug,
                        visible,
                        order,
                    }
                }
            }
            links {
                __typename
                ... on ComponentMenuLinks {
                    id,
                    label_ua,
                    label_ru,
                    label_en,
                    slug,
                    visible,
                    order,
                },
            }
            copyright {
                content_ua
                content_ru
                content_en
            }
        }
    }
`;

interface MenuItem {
  id: string;
  label_ua: string;
  label_ru: string;
  label_en: string;
  slug: string;
  visible: boolean;
  order: number;
  links?: MenuItem[]
}

interface FooterMenu {
  sections: MenuItem[]
  links: MenuItem[];
  copyright: {
    content_ua: string;
    content_ru: string;
    content_en: string;
  };
}

function Footer(): any {
  const {loading, error, data} = useQuery<{ footerMenu: FooterMenu }>(GET_CONTACTS);
  if (loading || !data) return '';
  if (error) return '';

  const visibleSections = data.footerMenu.sections
    .filter(menu => menu.visible)
    .sort((a, b) => a.order - b.order);
  const visibleLinks = data.footerMenu.links
    .filter(menu => menu.visible)
    .sort((a, b) => a.order - b.order);

  function getCurrentYear() {
    const d = new Date();
    return d.getFullYear();
  }

  return (
    <footer className="page-block-padding-x footer-block">
      <div className="row footer-site-links">
        {visibleSections.map((section) => (
          <div className="col-md-3 footer-site-links__block" key={section.id}>
            {section.slug && <h5 className="footer-item">
                <a href={getLocalizedUrl(section.slug)}><Text item={section} prop="label"/></a>
            </h5>}
            {!section.slug && <h5 className="footer-item"><Text item={section} prop="label"/></h5>}
            <ul className="list-unstyled text-small">
              {section.links?.slice().sort((a, b) => a.order - b.order).map((link) => (
                <li key={link.id} className="footer-item">
                  <a href={getLocalizedUrl(link.slug)}><Text item={link} prop="label"/></a>
                </li>
              ))}
            </ul>
            <hr/>
          </div>
        ))}
      </div>
      <div className="row pt-md-5 footer-legal-info">
        <div className="col-md-6 col-sm-12 footer-copyrights">
          © PRO-ORTO {getCurrentYear()}<span className="px-2">|</span><Text item={data.footerMenu.copyright} prop="content"/>
        </div>
        <div className="footer-legal-links col-md-6 col-sm-12 d-flex justify-content-end">
          {visibleLinks.map((link, index) => (
            <div key={link.id} className="footer-legal-links__item">
              {index !== 0 && (<span className="footer-legal-links__separator px-3">|</span>)}
              <a href={getLocalizedUrl(link.slug)}><Text item={link} prop="label"/></a>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
