import React from 'react';
import {Text} from "../../containers/lang/Text";
import './Schedule.css';
import {HeaderContactSchedule} from "../../containers/header/Header.model";


interface Schedule {
  schedule: HeaderContactSchedule
}

function Schedule({schedule}: Schedule): any {

  return (
    <div>
      {schedule.intervals.map(interval => (
        <div key={interval.id} className="schedule-item">
          <span className="schedule-item__weekday-name">
            <span className={'schedule-item__weekday-text' + (!interval.time ? ' schedule-item__weekday-text_weekend' : '')}>
              <Text item={interval} prop={'weekDays'} />
            </span>: {interval.time ? interval.time : <Text item={interval} prop={'title'} />}
          </span>
        </div>
      ))}
    </div>
  );
}

export default Schedule;
