import React from 'react';
import {Text} from "../../containers/lang/Text";

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Transparent = 'transparent',
  Emphasis = 'emphasis',
}

interface Button {
  type: 'submit' | 'reset' | 'button';
  textItem: any;
  textProp: string;
  customClass?: string;
  classType?: ButtonType;
  onClick?: () => void
}

export function getButtonClass(type?: ButtonType): string {
  switch (type) {
    case ButtonType.Primary:
      return 'btn-orto-primary';

    case ButtonType.Secondary:
      return 'btn-orto-secondary';

    case ButtonType.Transparent:
      return 'btn-orto-transparent';

    case ButtonType.Emphasis:
      return 'btn-orto-emphasis';

    default:
      return 'btn-orto-primary';
  }
}

function Button({type, classType, customClass, textItem, textProp, onClick}: Button): any {
  return (
    <button type={type}
            onClick={onClick}
            className={customClass + ' ' + getButtonClass(classType)}>
      <Text item={textItem} prop={textProp} />
    </button>
  );
}

export default Button;
