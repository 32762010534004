import React, {useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import HeaderMenu from "../../components/header-menu/HeaderMenu";
import HeaderContacts from "../../components/header-contacts/HeaderContact";
import {HeaderContact, HeaderMenuData} from "./Header.model";
import LanguageSelector from "../lang/LanguageSelector";
import './Header.css';

const GET_HEADER_DATA = gql`
    {
        headerMenu {
            body {
                __typename
                ... on ComponentMenuLinks {
                    id,
                    order,
                    label_ua,
                    label_ru,
                    label_en,
                    slug,
                    visible
                }
                __typename
                ... on ComponentPageButton {
                    id,
                    title_ua,
                    title_ru,
                    title_en,
                    slug
                }
            }
        }

        headerContact {
            contactsList {
                __typename
                ...on ComponentCommonContactsList {
                    contacts {
                        id,
                        enabled,
                        order,
                        type,
                        value
                    }
                }
            }
            schedule {
                __typename
                ...on ComponentCommonSchedule {
                    intervals {
                        id,
                        weekDays_ua,
                        weekDays_ru,
                        weekDays_en,
                        title_ua,
                        title_ru,
                        title_en,
                        time
                    }
                }
            }
        }
    }
`;

function Header(): any {
  const {
    loading,
    error,
    data
  } = useQuery<{ headerMenu: HeaderMenuData; headerContact: HeaderContact }>(GET_HEADER_DATA);
  const [hamburgerMenuVisibility, setHamburgerMenuVisibility] = useState(false);

  if (loading || !data) return '';
  if (error) return '';

  const toggleHamburgerMenu = () => {
    setHamburgerMenuVisibility(!hamburgerMenuVisibility);
    const rootEl = document.getElementById('root');
    if (rootEl) {
      rootEl.classList.toggle('header__hamburger-menu_visible', !hamburgerMenuVisibility);
    }
  }

  return (
    <div className="header">
      <div className={'header__hamburger-menu ' + (hamburgerMenuVisibility ? 'header__hamburger-menu_visible' : '')}>
        <div className="header__hamburger-title d-flex justify-content-between page-block-padding-x">
          <a href="/" className="my-0 font-weight-normal mr-md-5">
            <img src="/images/logo.svg" alt="PRO-ORTO" width="128px" height="15px"/>
          </a>
          {!hamburgerMenuVisibility &&
          <button className="header__hamburger-btn"
                  aria-label="Hamburger menu button"
                  onClick={toggleHamburgerMenu}>
              <hr/>
              <hr/>
              <hr/>
          </button>}
          {hamburgerMenuVisibility &&
          <button
              className="header__hamburger-btn-close btn-orto-close"
              aria-label="Close hamburger menu button"
              onClick={toggleHamburgerMenu}>x</button>}
        </div>

        {hamburgerMenuVisibility &&
        <div className="header__hamburger-menu-block">
            <div className="header__hamburger-menu-block-container">
                <div className="header__hamburger-menu-block-content">
                    <LanguageSelector/>
                    <HeaderMenu menu={data.headerMenu} toggleHamburgerMenu={toggleHamburgerMenu}/>
                    <HeaderContacts headerContact={data.headerContact}/>
                </div>
            </div>
        </div>}
      </div>
      <div className="header__regular-menu">
        <HeaderContacts headerContact={data.headerContact}/>
        <HeaderMenu menu={data.headerMenu}/>
      </div>
    </div>
  );
}

export default Header;
