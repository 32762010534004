import React from 'react';
import {useContext} from 'react';
import ReactMarkdown from 'react-markdown';
import {LanguageContext} from "./Language";
import {getPathToServerImage} from "../../components/image/Image";

// get text according to id & current language
// @ts-ignore
export function Text({item, prop, richText = false}) {
  const languageContext = useContext(LanguageContext);
  const lang = languageContext.userLanguage === 'uk' ? 'ua' : languageContext.userLanguage;
  const currentLangProp = `${prop}_${lang}`;

  return richText ? (
    <ReactMarkdown transformImageUri={getPathToServerImage} source={item[currentLangProp]}/>) : item[currentLangProp];
}
