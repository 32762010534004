import React, {useContext} from "react";
import {Route, Switch, useLocation} from "react-router-dom";

import {Routes} from "./RoutesConfig";
import RouteWithSubRoutes from "./RouteWithSubRoutes";
import ModalRequestForm from "../modal-request-form/ModalRequestForm";
import {LanguageContext} from "../lang/Language";


function AppRoutes() {
  const location: any = useLocation();
  const languageContext = useContext(LanguageContext);
  const background = location?.state?.background;

  return (
    <div>
      <Switch location={background || location}>
        {Routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route}/>
        ))}
      </Switch>

      {background && <Route path={`/${languageContext.userLanguage}/doctor-request`} children={<ModalRequestForm/>}/>}
    </div>
  );
}

export default AppRoutes;
