import {lazy, LazyExoticComponent} from "react";

const Home = lazy(() => import('../../pages/home/Home'));
const About = lazy(() => import('../../pages/about/About'));
const Laboratory = lazy(() => import('../../pages/laboratory/Laboratory'));
const Patients = lazy(() => import('../../pages/patients/Patients'));
const Doctors = lazy(() => import('../../pages/doctors/Doctors'));
const Terms = lazy(() => import('../../pages/terms/Terms'));
const Policy = lazy(() => import('../../pages/policy/Policy'));
const NotFound = lazy(() => import('../../pages/not-found/NotFound'));

export interface RouteConfig {
  path: string;
  children: LazyExoticComponent<any>;
  exact?: boolean;
  routes?: RouteConfig[];
}

export const Routes: RouteConfig[] = [
  {
    path: '/uk/',
    exact: true,
    children: Home,
  },
  {
    path: `/uk/for-patients`,
    exact: false,
    children: Patients
  },
  {
    path: '/uk/for-doctors',
    exact: false,
    children: Doctors
  },
  {
    path: '/uk/laboratory',
    exact: false,
    children: Laboratory
  },
  {
    path: '/uk/about',
    exact: false,
    children: About
  },
  {
    path: '/uk/term-of-service',
    exact: false,
    children: Terms
  },
  {
    path: '/uk/privacy-policy',
    exact: false,
    children: Policy
  },

  /* RU ----------------------------- */

  {
    path: '/ru/',
    exact: true,
    children: Home,
  },
  {
    path: `/ru/for-patients`,
    exact: false,
    children: Patients
  },
  {
    path: '/ru/for-doctors',
    exact: false,
    children: Doctors
  },
  {
    path: '/ru/laboratory',
    exact: false,
    children: Laboratory
  },
  {
    path: '/ru/about',
    exact: false,
    children: About
  },
  {
    path: '/ru/term-of-service',
    exact: false,
    children: Terms
  },
  {
    path: '/ru/privacy-policy',
    exact: false,
    children: Policy
  },

  /* EN ----------------------------- */

  {
    path: '/en/',
    exact: true,
    children: Home,
  },
  {
    path: `/en/for-patients`,
    exact: false,
    children: Patients
  },
  {
    path: '/en/for-doctors',
    exact: false,
    children: Doctors
  },
  {
    path: '/en/laboratory',
    exact: false,
    children: Laboratory
  },
  {
    path: '/en/about',
    exact: false,
    children: About
  },
  {
    path: '/en/term-of-service',
    exact: false,
    children: Terms
  },
  {
    path: '/en/privacy-policy',
    exact: false,
    children: Policy
  },
  {
    path: '',
    children: NotFound
  }
];
