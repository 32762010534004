import {ButtonType} from "../../components/button/Button";

export enum ComponentType {
  ComponentPageSlogan = 'ComponentPageSlogan',
  ComponentPageSloganMini = 'ComponentPageSloganMini',
  ComponentPageStatistic = 'ComponentPageStatistic',
  ComponentPageSlider = 'ComponentPageSlider',
  ComponentPageContentBlock = 'ComponentPageContentBlock',
  ComponentPageRecommendedDoctorsSlider = 'ComponentPageRecommendedDoctorsSlider',
  ComponentPageRequestForm = 'ComponentPageRequestForm',
  ComponentPageGallery = 'ComponentPageGallery',
  ComponentPagePartners = 'ComponentPagePartners',
  ComponentPageLaboratoryContent = 'ComponentPageLaboratoryContent',
  ComponentPageDownloadFile = 'ComponentPageDownloadFile',
  ComponentPagePartnersHealthyStart = 'ComponentPagePartnersHealthyStart',
  ComponentPageQuiz = 'ComponentPageQuiz',
  ComponentPageTreatmentTypes = 'ComponentPageTreatmentTypes',
  ComponentPageSuccesCases = 'ComponentPageSuccesCases',
  ComponentPageCoursesBlock = 'ComponentPageCoursesBlock',
  ComponentPageDoctorEvents = 'ComponentPageDoctorEvents',
  ComponentMenuLinks = 'ComponentMenuLinks',
  ComponentPageButton = 'ComponentPageButton',
  ComponentPageSimpleTextBlock = 'ComponentPageSimpleTextBlock',
  ComponentPagePartnerDescription = 'ComponentPagePartnerDescription'
}

export interface ComponentPageSlogan {
  __typename: ComponentType.ComponentPageSlogan;
  id: string;
  enabled: boolean;
  mainTitle_ua: string;
  mainTitle_ru: string;
  mainTitle_en: string;
  subTitle_ua: string;
  subTitle_ru: string;
  subTitle_en: string;
  order: number;
  media: {
    url: string;
  };
}

export interface ComponentPageSloganMini {
  __typename: ComponentType.ComponentPageSloganMini;
  id: string;
  enabled: boolean;
  bgOverlayColor: string;
  order: number;
  sloganMiniText_ua: string;
  sloganMiniText_ru: string;
  sloganMiniText_en: string;
  bgImage: {
    url: string;
  }
}

export interface PageButton {
  id: string;
  enabled: boolean;
  type: ButtonType;
  title_ua: string;
  title_ru: string;
  title_en: string;
  slug: string;
  order: number;
  isModalRoute?: boolean;
}

export interface ComponentPageContentBlock {
  __typename: ComponentType.ComponentPageContentBlock;
  id: string;
  enabled: boolean;
  header_ua: string;
  header_ru: string;
  header_en: string;
  text_ua: string;
  text_ru: string;
  text_en: string;
  title_ua: string;
  title_ru: string;
  title_en: string;
  order: number;
  mediaPosition: string;
  youtubeVideoId?: string;
  isMediaSymmetric: boolean;
  media?: {
    url: string;
  };
  pageContentBlockBgColor: string;
  contentBgColor: string;
  buttons: PageButton[];
}

export interface GalleryItem {
  id: string;
  order: number;
  enabled: boolean;
  media: { url: string };
  content_ua?: string;
  content_ru?: string;
  content_en?: string;
}

export interface ComponentPageGallery {
  __typename: ComponentType.ComponentPageGallery;
  id: string;
  enabled: boolean;
  order: number;
  title_ua: string;
  title_ru: string;
  title_en: string;
  slidesToScroll: number;
  slidesToShow: number;
  rows: number;
  media?: {
    url: string;
  }[];
  galleryItems: GalleryItem[];
}

export interface FormInput {
  order: number;
  required: boolean;
  type: any;
  label_ua?: string;
  label_ru?: string;
  label_en?: string;
  errorMsg_ua?: string;
  errorMsg_ru?: string;
  errorMsg_en?: string;
  errorMessages?: {
    required?: {
      message_ua: string;
      message_ru: string;
      message_en: string;
    };
    pattern?: {
      message_ua: string;
      message_ru: string;
      message_en: string;
    };
  };
  mask?: string;
}

export interface SeoData {
  title_ua: string;
  title_ru: string;
  title_en: string;
  description_ua: string;
  description_ru: string;
  description_en: string;
}

export interface FormCheckbox {
  order: number;
  required: boolean;
  label_ua: string;
  label_ru: string;
  label_en: string;
  labelLink_ua?: string;
  labelLink_ru?: string;
  labelLink_en?: string;
  slug?: string;
}

export interface FormButton {
  order: number;
  label_ua: string;
  label_ru: string;
  label_en: string;
  type: ButtonType;
  forModalClosing?: boolean;
}

export interface DaySelect {
  order: number;
  label_ua: string;
  label_ru: string;
  label_en: string;
  day_slots: {
    id: string;
    label_ua: string;
    label_ru: string;
    label_en: string;
    order: number;
    available: boolean
  }[]
}

export interface TimeSelect {
  order: number
  time_slots: {
    id: string;
    order: number;
    available: boolean
    from: string;
    to: string;
  }[]
}

export interface ComponentPageRequestForm {
  __typename: ComponentType.ComponentPageRequestForm;
  id: string;
  order: number;
  title_ua: string;
  title_ru: string;
  title_en: string;
  nonRequiredLabel_ua: string;
  nonRequiredLabel_ru: string;
  nonRequiredLabel_en: string;
  blockBgColor: string;
  successMessage_ua: string;
  successMessage_ru: string;
  successMessage_en: string;
  successMessageTitle_ua: string;
  successMessageTitle_ru: string;
  successMessageTitle_en: string;
  errorMessage_ua: string;
  errorMessage_ru: string;
  errorMessage_en: string;
  errorMessageTitle_ua: string;
  errorMessageTitle_ru: string;
  errorMessageTitle_en: string;
  image: {
    url: string;
  }
  enabled: boolean;
  name: FormInput;
  phone: FormInput;
  daySelect: DaySelect;
  timeSelect: TimeSelect;
  comment: FormInput;
  personalDataProcessing: FormCheckbox;
  submitBtn: FormButton;
  cancelBtn: FormButton;
}

export interface ComponentPageSimpleTextBlock {
  __typename: ComponentType.ComponentPageSimpleTextBlock;
  id: string;
  order: number;
  enabled: boolean;
  text_ua: string;
  text_ru: string;
  text_en: string;
  simpleTextBgColor: string;
}

export interface ComponentPagePartnerDescription {
  __typename: ComponentType.ComponentPagePartnerDescription;
  id: string;
  order: number;
  enabled: boolean;
  partnerHeader_ua: string;
  partnerHeader_ru: string;
  partnerHeader_en: string;
  title_ua: string;
  title_ru: string;
  title_en: string;
  description_ua: string;
  description_ru: string;
  description_en: string;
  partnerBlockBgColor: string;
  partnerMediaPosition: string;
  media: {
    url: string
  }
}

export interface LegalPage {
  seo: SeoData;
  updatedAt: string;
  content: {
    title_ua: string;
    title_ru: string;
    title_en: string;
    subTitle_ua: string;
    subTitle_ru: string;
    subTitle_en: string;
    text_ua: string;
    text_ru: string;
    text_en: string;
    updateText_ua: string;
    updateText_ru: string;
    updateText_en: string;
    image: {
      url: string;
    }
  }
}
