import React, {useState} from 'react';
import Modal from 'react-modal';
import {useHistory} from "react-router";
import {gql, useQuery} from "@apollo/client";
import Loading from "../../components/loading/Loading";
import {ComponentPageRequestForm} from "../../pages/common/Common.model";
import RequestForm from "../../components/request-form/RequestForm";
import './ModalRequestForm.css';

Modal.setAppElement('#root')

const GET_REQUEST_FORM = gql`
    {
        requestForm {
            content {
                __typename
                ... on ComponentPageRequestForm {
                    id,
                    order,
                    title_ua,
                    title_ru,
                    title_en,
                    nonRequiredLabel_ua,
                    nonRequiredLabel_ru,
                    nonRequiredLabel_en,
                    enabled,
                    blockBgColor,
                    successMessage_ua,
                    successMessage_ru,
                    successMessage_en,
                    successMessageTitle_ua,
                    successMessageTitle_ru,
                    successMessageTitle_en,
                    errorMessage_ua,
                    errorMessage_ru,
                    errorMessage_en,
                    errorMessageTitle_ua,
                    errorMessageTitle_ru,
                    errorMessageTitle_en,
                    image {
                        url
                    }
                    name {
                        order,
                        label_ua,
                        label_ru,
                        label_en,
                        required,
                        errorMessages,
                        type
                    },
                    phone {
                        order,
                        label_ua,
                        label_ru,
                        label_en,
                        required,
                        errorMessages,
                        mask,
                        type
                    },
                    daySelect {
                        order,
                        label_ua,
                        label_ru,
                        label_en,
                        day_slots {
                            id,
                            label_ua,
                            label_ru,
                            label_en,
                            order,
                            available
                        }
                    },
                    timeSelect {
                        order,
                        time_slots {
                            id,
                            order,
                            available,
                            from,
                            to
                        }
                    },
                    comment {
                        order,
                        label_ua,
                        label_ru,
                        label_en,
                        required,
                        type
                    },
                    personalDataProcessing {
                        order,
                        required,
                        label_ua,
                        label_ru,
                        label_en,
                        labelLink_ua,
                        labelLink_ru,
                        labelLink_en,
                        slug
                    },
                    submitBtn {
                        order,
                        label_ua,
                        label_ru,
                        label_en,
                        type
                    },
                    cancelBtn {
                        order,
                        label_ua,
                        label_ru,
                        label_en,
                        type,
                        forModalClosing
                    }
                }
            }
        }
    }
`;

function ModalRequestForm(): any {
  const history = useHistory();
  const [modalIsOpen, setIsOpen] = useState(true);
  const [modalStateClass, setModalStateClass] = useState('');

  const {loading, error, data} = useQuery<{ requestForm: { content: ComponentPageRequestForm } }>(GET_REQUEST_FORM);
  if (loading) return <Loading/>;
  if (!data || error) return '';

  function closeModal() {
    setIsOpen(false);
    setModalStateClass('');
    history.goBack();
  }

  return (
    <Modal isOpen={modalIsOpen}
           onRequestClose={closeModal}
           className="client-request__modal-content"
           overlayClassName="client-request__modal-overlay"
           contentLabel="Text">
      <div className={'client-request__modal-form ' + modalStateClass}>
        <div className="client-request__modal-title-image">&nbsp;</div>
        <button className="btn-orto-close" onClick={() => closeModal()}>x</button>
        <div className="client-request__modal-custom-overlay" onClick={closeModal}>&nbsp;</div>
        <RequestForm formData={data.requestForm.content} isOpenedInModal={true} closeModal={closeModal}
                     setFormStateClass={(cl) => {
                       setModalStateClass(cl)
                     }}/>
      </div>
    </Modal>
  );
}

export default ModalRequestForm;
