import React from 'react';
import './Contacts.css';
import {ContactType, HeaderContactContactsList} from "../../containers/header/Header.model";

interface ContactsList {
  contactsList: HeaderContactContactsList
}

function Contacts({contactsList}: ContactsList): any {
  const orderedContacts = contactsList.contacts
    .filter(c => c.enabled)
    .sort((a, b) => a.order - b.order)

  const contactsWithoutEmail = orderedContacts.filter(c => c.type !== ContactType.Email && c.type !== ContactType.Instagram);
  const instagramContact = orderedContacts.find(c => c.type === ContactType.Instagram);

  const checkIfLink = (address: string): boolean => {
    return address.split('http')?.length > 1;
  }

  const getInstaId = (instaLink: string): string => {
    const splitUrl = instaLink.split('instagram.com/');

    if (!splitUrl || !splitUrl[1]) {
      return instaLink;
    }

    const id = splitUrl[1];
    return id?.replaceAll('/', '');
  }

  return (
    <>
      <ul className="contacts-block contacts-block_instagram">
        {instagramContact &&
            <li className="contacts-item">
                <img src={'/images/contacts/' + instagramContact.type + '.svg'} alt={instagramContact.type} height={20} width={20}/>
                <a href={(checkIfLink(instagramContact.value) ? '' : 'https://') + instagramContact.value}
                   target="_blank"
                   rel="noopener noreferrer">{getInstaId(instagramContact.value)}</a>
            </li>
        }
      </ul>
      <ul className="contacts-block">
        {orderedContacts
          .map(contact => (
            contact.type !== ContactType.Instagram && (
              <li key={contact.id} className="contacts-item">
                {contact.type !== ContactType.Email && contactsWithoutEmail.length === 1 && (
                  <div className="contacts-item__multi-icon">
                    <img src={'/images/contacts/viber.svg'} alt={contact.type} height={20} width={20}/>
                    <img src={'/images/contacts/telegram.svg'} alt={contact.type} height={20} width={20}/>
                    <img src={'/images/contacts/phone.svg'} alt={contact.type} height={20} width={20}/>
                  </div>
                )}
                {(contact.type === ContactType.Email || contactsWithoutEmail.length > 1) && (
                  <img src={'/images/contacts/' + contact.type + '.svg'} alt={contact.type} height={20} width={20}/>
                )}
                {contact.type === ContactType.Email && (<a href={'mailto:' + contact.value}>{contact.value}</a>)}
                {contact.type !== ContactType.Email && (<a href={'tel:' + contact.value}>{contact.value}</a>)}
              </li>
            )
          ))}
      </ul>
    </>
  );
}

export default Contacts;
